@import '../../../styles/shared';

.wrapper {
  font-family: var(--classic-header-text-font-family);
  color: var(--classic-header-color);
  background-color: var(--classic-header-background-color);
}

.largeWrapper {
  .body {
    min-height: var(--classic-header-large-height);
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  min-height: var(--classic-header-height);
}

.bodyLeft {
  z-index: 1;
}

.bodyRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @include mq($from: desktop) {
    min-width: 300px;
  }
}

.logo {
  img {
    display: block;
    height: var(--classic-header-logo-height);
  }
}

.breadcrumbsWrapper + .headingWrapper {
  margin-top: 2px;
}

.searchWrapper {
  display: none;

  @include mq($from: desktop) {
    display: block;
    min-width: 300px;
  }
}

.mobileSearchWrapper {
  padding: 30px $gutter-width 0;
  background-color: var(--hub-nav-background-color);
}

.mobileSearchButtonWrapper {
  @include mq($from: desktop) {
    display: none;
  }
}

.textContentWrapper {
  display: none;

  @include mq($from: desktop) {
    display: block;
    max-width: 460px;
  }
}

.textContentWrapperMobile {
  position: relative;
  padding: 16px 0;

  @include mq($from: desktop) {
    display: none;
  }

  &::before {
    position: absolute;
    top: -2px;
    right: -20px;
    left: -20px;
    content: '';
    display: block;
    height: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &::after {
    position: absolute;
    top: -12px;
    right: -20px;
    left: -20px;
    content: '';
    display: block;
    height: 10px;
    background: var(--classic-background-color);
  }
}

.burgerIcon {
  @include mq($from: desktop) {
    display: none;
  }
}

.mobileNavBar {
  position: fixed;
  top: var(--classic-header-height);
  right: 0;
  left: 0;
  height: calc(100vh - var(--classic-header-height));
  padding-bottom: var(--informa-ribbon-height);
  z-index: 100;
  overflow-y: auto;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--hub-nav-background-color);

  @include mq($from: desktop) {
    display: none;
  }
}
