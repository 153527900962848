@import '../../../../styles/shared';

.heading {
  font-family: var(--classic-header-heading-font-family);
  font-size: 14px;
  font-weight: var(--classic-header-heading-font-weight);

  @include mq($from: tablet) {
    font-size: 18px;
  }

  @include mq($from: desktop) {
    font-size: 24px;
  }

  @include mq($from: wide) {
    font-size: 28px;
  }
}
